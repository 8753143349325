import { Collapse, Button, DatePicker, Radio, Select, Form, Input, Upload, message, InputNumber, Space } from 'antd'
import { UploadOutlined, CopyOutlined, PlusOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import ReactDOM from 'react-dom'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { fetchQuestById, updateQuest } from '../../../store/quests/actions'
import { fetchAllActivitiesForCreateEditQuest } from '../../../store/activity/actions'
// import { fetchAllGoals } from '../../../store/goal/actions'
import { fetchAllCommunity } from '../../../store/community/actions'
import { fetchAllRewadsForQuest } from '../../../store/rewards/actions'
import ServerApi from '../../../utility/ServerApi'
// import moment from 'moment'
import * as dayjs from 'dayjs'
// import { SDGS } from '../../../const/sdgs.const'
import PreviewImage from '../../../components/image/PreviewImage'
import CopyToClipboard from 'react-copy-to-clipboard'
import { fetchAllSDGS } from '../../../store/sdgs/actions'
import { fetchPublishedLocations } from '../../../store/locations/actions'
import { canIAccessThisWithMyRoles, isSupportedFileFormatForGeneric, SUPPORTED_FILE_FORMAT_EXTRA, transformValidationMethod } from '../../../const/utils'
import { LOGIN_REQUIRED_MODE, QUEST_WINDOW_MODE, transformVoucherCodeInput } from './Quests.const'
import { fetchCommonConfiguration } from '../../../store/common/actions'
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertFromHTML, ContentState, convertToRaw } from "draft-js"
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
// import LazyImage from '../../../components/image/LazyImage'
import CustomColorPicker from './CustomColorPicker'
import { SUSGAIN_ROLES } from '../../../const/roles'
import { fetchAvailQuestLeaders } from '../../../store/users/adminUsers/actions'
const { Panel } = Collapse
const { RangePicker } = DatePicker

export const EditQuest = () => {
    const [messageApi, contextHolder] = message.useMessage()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { questId } = useParams()
    const questsData = useSelector(state => state.quests.quest)
    const activityData = useSelector(state => state.activity.activitiesForEditCreateQuest)
    const communityData = useSelector(state => state.community.communities)
    const rewards = useSelector(state => state.rewards.rewards)
    const sdgs = useSelector(state => state.sdgs.sdgs)
    const loginMode = useSelector(state => Object.values(LOGIN_REQUIRED_MODE))
    // const locations = useSelector(state => state.locations.locations)
    const [claimLimitOption, setClaimLimitOption] = useState("")
    const [rewardOption, setRewardOption] = useState("")
    // const [fileList, setFileList] = useState([])
    // const [thumbnailFileList, setThumbnailFileList] = useState([])
    const [orgLogoFileList, setOrgLogoFileList] = useState([])
    const [orgBackgroundFileList, setOrgBackgroundFileList] = useState([])
    // const [voucherCodes, setVoucherCodes] = useState(questsData?.rewardDetails?.voucher?.voucher_codes)
    const [selectedCommunity, setSelectedCommunity] = useState(questsData?.communityIds?.[0]?._id || null)
    const [selectedCity, setSelectedCity] = useState(questsData?.cities || null)
    const [completionWindowMode, setCompletionWindowMode] = useState(QUEST_WINDOW_MODE.TIME_PERIOD.value)
    // const [questCurrentTheme, setQuestCurrentTheme] = useState('')//custom or hex
    const [questCurrentThemeColor, setQuestCurrentThemeColor] = useState(null)

    const [editorDescriptioDetailState, setEditorDescriptioDetailState] = useState(EditorState.createEmpty())
    const editorDescriptionDetailText = useSelector(state => state?.quests?.quest?.description)
    const [descriptionDetail, setDescriptionDetail] = useState()

    // const [emailContentEditorState, setEmailContentEditorState] = useState(EditorState.createEmpty())
    // const [emailContent, setEmailContent] = useState('')
    // const editorEmailContentText = useSelector(state => state?.quests?.quest?.rewardDetails?.voucher?.email_content)
    // const [emailLogo, setEmailLogo] = useState([])
    const [numberVoucherOriginalEntered, setNumberVoucherOriginalEntered] = useState(0)
    const [numberVoucherEntered, setNumberVoucherEntered] = useState(0)
    // const claimLimitOptions = useSelector(state => state.common?.data?.claimLimitOptions)
    const themeBackgroundPreviewRef = useRef(null)
    const themeContainerPreviewRef = useRef(null)
    const adminUsers = useSelector(state => state.adminUsers.availQuestLeaders)

    const filteredAdminUsers = adminUsers?.result?.filter((values) => {
        return values?.role?.includes('quest_leader')
    })
    const themePrimaryPreviewCSS = {
        background: questCurrentThemeColor,
        padding: '8px 0'
    }

    const themeBackgroundPreviewCSS = {
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, ${questCurrentThemeColor}, ${questCurrentThemeColor})
        `,
        padding: '8px 0'
    }

    const themeContainerPreviewCSS = {
        background: `linear-gradient(0deg, rgba(13, 12, 12, 0.9), rgba(13, 12, 12, 0.9)), linear-gradient(0deg, ${questCurrentThemeColor}, ${questCurrentThemeColor})`,
        padding: '8px 0'
    }
    useEffect(() => {
        setTimeout(() => {
            // const blocksFromHTML = convertFromHTML(editorDescriptionDetailText)
            // const state = ContentState.createFromBlockArray(
            //     blocksFromHTML.contentBlocks,
            //     blocksFromHTML.entityMap
            // )
            // setEditorDescriptioDetailState(EditorState.createWithContent(state))
            // setDescriptionDetail(editorDescriptionDetailText)
            setDescriptionDetail(editorDescriptionDetailText)
            const contentBlock = htmlToDraft(editorDescriptionDetailText || '')
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                const editorState = EditorState.createWithContent(contentState)
                setEditorDescriptioDetailState(editorState)
            }
        }, 1000)
    }, [editorDescriptionDetailText])

    // useEffect(() => {
    //     setTimeout(() => {            
    //         setEmailContent(editorEmailContentText)
    //         const contentBlock = htmlToDraft(editorEmailContentText || '')
    //         if (contentBlock) {
    //             const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    //             const editorState = EditorState.createWithContent(contentState)
    //             setEmailContentEditorState(editorState)
    //         }
    //     }, 1000)
    // }, [editorEmailContentText])

    const onEditorDescriptionDetailStateChange = (editorState) => {
        setEditorDescriptioDetailState(editorState)
    }

    // const onEditorEmailStateChange = (editorState) => {
    //     setEmailContentEditorState(editorState)
    // }

    useEffect(() => {
        // console.log(`questId ssss `, questId)
        dispatch(fetchCommonConfiguration())
        dispatch(fetchQuestById(questId))
        dispatch(fetchAllCommunity())
        dispatch(fetchAllRewadsForQuest())
        dispatch(fetchAllSDGS())
        dispatch(fetchPublishedLocations())
        dispatch(fetchAvailQuestLeaders())
    }, [])

    // useEffect(() => {
    //     //teams options based on selected community
    //     dispatch(fetchAllActivitiesForCreateEditQuest({ communityId: selectedCommunity, city: selectedCity }))
    //     // setSelectedCity(selectedCommunity.cities)
    //     // force reset team selected
    //     form.setFieldsValue('activityIds', undefined)
    // }, [selectedCommunity])

    // useEffect(() => {
    //     // console.log(`activityData changed `)        
    // }, [activityData])

    useEffect(() => {
        //teams options based on selected community
        dispatch(fetchAllActivitiesForCreateEditQuest({ communityId: selectedCommunity, city: selectedCity, questId }))
        // force reset team selected
        form.setFieldsValue({ activityIds: undefined })
    }, [selectedCity, selectedCommunity])

    useEffect(() => {
        form.setFieldsValue({
            leaderId: questsData?.leaderId,
            orgName: questsData?.brandInfo?.orgName,
            theme: questsData?.brandInfo?.theme,
            name: questsData?.name,
            shareUrl: questsData?.qrCode?.share_url,
            communityIds: questsData?.communityIds?.[0]?._id,
            welcomeQuestMailTemplateId: questsData?.welcomeQuestMailTemplateId,
            welcomeQuestMailSubject: questsData?.welcomeQuestMailSubject,
            completedQuestMailTemplateId: questsData?.completedQuestMailTemplateId,
            completedQuestMailSubject: questsData?.completedQuestMailSubject,
            cities: questsData?.cities,
            timePeriod: [dayjs(questsData?.timePeriod?.from || new Date), dayjs(questsData?.timePeriod?.to || new Date)],
            completeWithin: questsData?.completeWithin,
            windowMode: questsData?.windowMode || QUEST_WINDOW_MODE.TIME_PERIOD.value,
            points: questsData?.points,
            activityIds: questsData?.activityIds?.map(i => i._id),
            habit: questsData?.habit,
            rewardId: { label: questsData?.rewardId?.name, value: questsData?.rewardId?._id },
            claimLimit: questsData?.rewardDetails?.claimLimit,
            extraPoint: questsData?.rewardDetails?.extraPoint,
            voucher_codes: questsData?.rewardDetails?.voucher?.voucher_codes,
            original_voucher_codes: questsData?.rewardDetails?.voucher?.original_voucher_codes,
            // email_content: questsData?.rewardDetails?.voucher?.email_content,
            // email_logo: questsData?.rewardDetails?.voucher?.email_logo,
            zepto_mail_template_id: questsData?.rewardDetails?.voucher?.zepto_mail_template_id,
            plantTree: questsData?.rewardDetails?.tree,
            mealForChildren: questsData?.rewardDetails?.meal,
            removeTrash: questsData?.rewardDetails?.trash,
            description: questsData?.description,
            instructions: questsData?.instructions,
            sdgs: questsData?.sdgs?.map(i => i?._id),
            publish: questsData?.publish,
            loginRequiredMode: questsData?.loginRequiredMode || LOGIN_REQUIRED_MODE.MANDATORY.value
        })
        setCompletionWindowMode(questsData?.windowMode || QUEST_WINDOW_MODE.TIME_PERIOD.value)
        setRewardOption(rewards.find(i => i._id === questsData?.rewardId?._id)?.rewardId)
        dispatch(fetchAllActivitiesForCreateEditQuest({ city: questsData?.cities, communityId: questsData?.communityIds?.[0]?._id }))
        setNumberVoucherEntered(questsData?.rewardDetails?.voucher?.voucher_codes?.split(',').length)
        setNumberVoucherOriginalEntered(questsData?.rewardDetails?.voucher?.original_voucher_codes?.split(',').length)
        setClaimLimitOption(questsData?.rewardDetails?.claimLimit)
        // setSelectedCity(questsData?.cities)
        // setSelectedCommunity(questsData?.communityIds?.[0]?._id)
        setQuestCurrentThemeColor(questsData?.brandInfo?.theme?.primary_color || '#119D49')
    }, [questsData])

    const successCallback = () => {
        navigate('/quests')
    }

    const updateNewQuest = (values) => {
        const _rewardId = typeof values.rewardId === 'string' ? values.rewardId : values.rewardId?._value
        const _voucher_codes = transformVoucherCodeInput(values.voucher_codes)
        values.description = descriptionDetail
        //get brand color
        const bgColor = window.getComputedStyle(ReactDOM.findDOMNode(themeBackgroundPreviewRef?.current))['background-image']
        // console.log(window.getComputedStyle(ReactDOM.findDOMNode(themeContainerPreviewRef?.current)))
        const containerColor = window.getComputedStyle(ReactDOM.findDOMNode(themeContainerPreviewRef?.current))['background-image']
        const data = {
            leaderId: values.leaderId,
            name: values.name,
            communityIds: values.communityIds || null,
            cities: values.cities,
            windowMode: completionWindowMode,
            completeWithin: values.completeWithin,
            timePeriod: {
                from: values.timePeriod ? values.timePeriod[0] : '',
                to: values.timePeriod ? values.timePeriod[1] : ''
            },
            welcomeQuestMailTemplateId: values.welcomeQuestMailTemplateId,
            welcomeQuestMailSubject: values.welcomeQuestMailSubject,
            completedQuestMailTemplateId: values.completedQuestMailTemplateId,
            completedQuestMailSubject: values.completedQuestMailSubject,
            totalnumber: values.totalnumber,
            activityIds: values.activityIds,
            habit: values.habit,
            points: values.points,
            rewardId: _rewardId,
            rewardDetails: {
                extraPoint: values.extraPoint,
                voucher: _voucher_codes.trim() !== '' ? {
                    voucher_codes: transformVoucherCodeInput(values.voucher_codes),
                    // email_content: emailContent,
                    // email_logo: emailLogo[0]?.image || questsData?.rewardDetails?.voucher?.email_logo
                    original_voucher_codes: transformVoucherCodeInput(values.original_voucher_codes),
                    zepto_mail_template_id: values.zepto_mail_template_id
                } : null,
                tree: values.plantTree,
                meal: values.mealForChildren,
                trash: values.removeTrash,
                claimLimit: claimLimitOption
            },
            description: values.description,
            instructions: values.instructions,
            sdgs: values.sdgs,
            publish: values.publish,
            loginRequiredMode: values.loginRequiredMode || LOGIN_REQUIRED_MODE.MANDATORY.value,
            brandInfo: {
                orgName: values.orgName,
                logo: questsData?.brandInfo?.logo,
                theme: {
                    primary_color: questCurrentThemeColor,
                    background_color: bgColor,
                    container_color: containerColor
                },
                backgroundImage: questsData?.brandInfo?.backgroundImage
            }
        }
        if (orgLogoFileList[0]) data.brandInfo.logo = orgLogoFileList[0].image
        if (orgBackgroundFileList[0]) data.brandInfo.backgroundImage = orgBackgroundFileList[0].image
        // if (fileList[0]) data.image = fileList[0].image
        // if (thumbnailFileList[0]) data.thumbnail = thumbnailFileList[0].icon
        // if (voucherFileList[0]) values.thumbUrl = voucherFileList[0].image
        dispatch(updateQuest(questId, data, successCallback))
    }

    const _showError = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg
        })
    }

    const handleOrgLogo = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     _showError('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            _showError('You can only upload JPG/PNG file!')
            return
        }
        setOrgLogoFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'activities')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setOrgLogoFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        image: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const handleOrgBackground = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     _showError('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            _showError('You can only upload JPG/PNG file!')
            return
        }
        setOrgBackgroundFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'activities')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setOrgBackgroundFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        image: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const orgLogoProps = {
        customRequest: handleOrgLogo,
        listType: 'picture',
        onRemove: () => setOrgLogoFileList([]),
        orgLogoFileList
    }

    const orgBackgroundProps = {
        customRequest: handleOrgBackground,
        listType: 'picture',
        onRemove: () => setOrgBackgroundFileList([]),
        orgBackgroundFileList
    }

    /**
     * #43616 - QUEST - time period logic update
     * @param {*} e 
     */
    const handleWindowModeChange = (e) => {
        setCompletionWindowMode(e.target.value)
    }

    // const handleQuestThemeChange = (e) => {
    //     setQuestCurrentTheme(e.target.value)
    // }

    const handleOnChangeColorComplete = (color) => {
        setQuestCurrentThemeColor(color.toHexString())
    }

    const handleCountVoucher = (e) => {
        try {
            if (e?.target?.value !== '') {
                const splittedVoucher = e.target.value.split(',')
                //check for empty element
                const newArr = splittedVoucher.filter(element => {
                    if (element === "") {
                        return false
                    } else {
                        return true
                    }
                })
                setNumberVoucherEntered(newArr.length)
            } else {
                setNumberVoucherEntered(0)
            }
        } catch (e) {

        }
    }

    const handleCountVoucherOriginal = (e) => {
        try {
            if (e?.target?.value !== '') {
                const splittedVoucher = e.target.value.split(',')
                //check for empty element
                const newArr = splittedVoucher.filter(element => {
                    if (element === "") {
                        return false
                    } else {
                        return true
                    }
                })
                setNumberVoucherOriginalEntered(newArr.length)
            } else {
                setNumberVoucherOriginalEntered(0)
            }
        } catch (e) {

        }
    }

    const canAccess = () => {
        return canIAccessThisWithMyRoles([SUSGAIN_ROLES.ADMIN.role])
    }

    const _postCopied = () => {
        if (questsData?.qrCode?.share_url) {
            messageApi.open({
                type: 'success',
                content: 'Link copied to clipboard'
            })
        } else {
            messageApi.open({
                type: 'warning',
                content: 'Link not available'
            })
        }
    }

    return (
        <>
            {contextHolder}
            <div className="shadow bg-white">
                <Form
                    name="EditQuests"
                    form={form}
                    labelCol={{
                        span: 4
                    }}
                    onFinish={(values) => updateNewQuest(values)}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Collapse className="border-none" expandIconPosition={'end'} defaultActiveKey={['leader', 'branding', '1', '2', '3', '4']}>
                        {canAccess() &&
                            <Panel header={<p className='font-bold text-lg'>Leader Account Info</p>} Info key="leader" className="bg-textbackgroundcolor">

                                <div className='flex justify-between'>
                                    <Form.Item
                                        labelAlign='left'
                                        label='Leader Account'
                                        className='w-full'
                                        name='leaderId'
                                        colon={false}
                                        rules={[{ required: true, message: 'Please select a Quest Leader!' }]}
                                    >
                                        <Select
                                            placeholder='Select a Quest Leader account'
                                            options={filteredAdminUsers?.map(i => ({ label: i?.name, value: i?._id }))}
                                        ></Select>

                                    </Form.Item>
                                    <Button
                                        icon={<PlusOutlined />} type="primary" className="bg-[#207868] ml-5"
                                        onClick={() => navigate('/users/admin')}
                                    >Create New Quest Leader</Button>
                                </div>
                            </Panel>
                        }
                        <Panel header={<p className='font-bold text-lg'>Branding Info</p>} key="branding" className="bg-textbackgroundcolor">
                            <Form.Item
                                label="Name of Organization"
                                name="orgName"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Name of Organization!' }]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Logo"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.QUEST_ORG_LOGO}
                            >
                                <div className='flex justify-between'>
                                    <div className='self-center'>
                                        <p className='flex'>Currently : <PreviewImage src={questsData?.brandInfo?.logo} width={120} height={120} /></p>
                                    </div>
                                    <Upload className='flex space-x-4' {...orgLogoProps} fileList={orgLogoFileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Background Image"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.QUEST_ORG_BG}
                            >
                                <div className='flex justify-between'>
                                    <div className='self-center'>
                                        <p className='flex'>Currently : <PreviewImage src={questsData?.brandInfo?.backgroundImage} width={150} height={100} /></p>
                                    </div>
                                    <Upload className='flex space-x-4' {...orgBackgroundProps} fileList={orgBackgroundFileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                name="theme"
                                colon={false}
                                labelAlign="left"
                                label="Theme"
                                rules={[{ required: false, message: 'Please select theme' }]}
                            // onChange={handleQuestThemeChange}
                            >
                                <Space direction='vertical'>
                                    <Space>
                                        {questCurrentThemeColor &&
                                            // <ColorPicker
                                            //     value={questCurrentThemeColor} showText
                                            //     onChangeComplete={handleOnChangeColorComplete} />
                                            <CustomColorPicker
                                                value={questCurrentThemeColor}
                                                showText
                                                disabledAlpha
                                                onChangeComplete={handleOnChangeColorComplete}></CustomColorPicker>
                                        }
                                    </Space>
                                    <Space>
                                        {/* <label>Preview:</label> */}
                                        <Space direction='vertical'><label>Primary</label>
                                            <div id='themePrimaryPreviewCSS' className={`w-20 h-20`} style={themePrimaryPreviewCSS}></div></Space>
                                        <Space direction='vertical'><label>Background</label>
                                            <div ref={themeBackgroundPreviewRef} className="w-20 h-20" style={themeBackgroundPreviewCSS}></div></Space>
                                        <Space direction='vertical'><label>Container</label>
                                            <div ref={themeContainerPreviewRef} className="w-20 h-20" style={themeContainerPreviewCSS}></div></Space>
                                    </Space>
                                </Space>
                            </Form.Item>
                        </Panel>
                        <Panel header={<p className='font-bold text-lg'>General Info</p>} key="1" className="bg-textbackgroundcolor">
                            <Form.Item
                                label="Quest Name"
                                name="name"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Quest name!' }]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                            <div className='flex justify-between'>
                                <Form.Item
                                    label="Share Link"
                                    name="shareUrl"
                                    labelAlign="left"
                                    labelCol={{ span: 4 }}
                                    className='w-full ml-2'
                                    colon={false}
                                    hasFeedback
                                >
                                    <Input disabled />
                                </Form.Item>
                                <CopyToClipboard text={questsData?.qrCode?.share_url} title='copy'>
                                    <Button icon={<CopyOutlined />} onClick={_postCopied} />
                                </CopyToClipboard>
                            </div>
                            {/* <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Quest Thumbnail"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.QUEST_THUMB}
                            >
                                <div className='flex justify-between'>
                                    <div className='self-center'>
                                        <p className='flex'>Currently : <PreviewImage src={questsData?.thumbnail} width={120} height={69} /></p>
                                    </div>
                                    <Upload className='flex space-x-4' {...thumbnailProps} fileList={thumbnailFileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Quest Image"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.QUEST_MAIN}
                            >
                                <div className='flex justify-between'>
                                    <div className='self-center'>
                                        <p className='flex'>Currently: <PreviewImage src={questsData?.image} width={105} height={69} /></p>
                                    </div>
                                    <Upload className='flex space-x-4' {...props} fileList={fileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>*/}
                            <Form.Item
                                label="Community"
                                name="communityIds"
                                labelAlign="left"
                                colon={false}
                                extra="Leave blank if global"
                            // rules={[{ required: true, message: 'Please select community!' }]}
                            // hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select community"
                                    optionFilterProp='label'
                                    onChange={(h) => { setSelectedCommunity(h) }}
                                    options={communityData.result?.map(i => ({ label: i.name, value: i._id }))}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                            {/*
                            <Form.Item
                                label="Cities"
                                name="cities"
                                labelAlign="left"
                                colon={false}
                                // rules={[{ required: true, message: 'Please select cities!' }]}
                                hasFeedback
                                extra={'Leave blank if global'}
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    optionFilterProp="label"
                                    placeholder="Select City"
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    onChange={(h) => { setSelectedCity(h) }}
                                    suffixIcon={<CgArrowsV />}
                                    options={locations?.map(i => ({ label: i?.name, value: i?._id }))}
                                >
                                </Select>
                            </Form.Item> */}
                            <Form.Item name="windowMode"
                                colon={false} labelAlign="left"
                                label="Completion window mode"
                                rules={[{ required: true, message: 'Please completion mode' }]}
                                onChange={handleWindowModeChange}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={QUEST_WINDOW_MODE.TIME_PERIOD.value}>{QUEST_WINDOW_MODE.TIME_PERIOD.label}</Radio>
                                    <Radio value={QUEST_WINDOW_MODE.COMPLETE_WITHIN.value}>{QUEST_WINDOW_MODE.COMPLETE_WITHIN.label}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {completionWindowMode == QUEST_WINDOW_MODE.TIME_PERIOD.value &&
                                <div className="grid grid-cols-7 gap-3">
                                    <Form.Item
                                        label="From / To Date"
                                        name="timePeriod"
                                        labelAlign="left"
                                        colon={false}
                                        rules={[{ required: true, message: 'Please enter duration!' }]}
                                        labelCol={{
                                            span: 7
                                        }}
                                        className="col-span-4"
                                        extra='The quest will be available from date and to date only'
                                    >
                                        <RangePicker style={{ width: "100%" }} />
                                    </Form.Item>
                                </div>
                            }
                            {completionWindowMode == QUEST_WINDOW_MODE.COMPLETE_WITHIN.value &&
                                <>
                                    <Form.Item
                                        label="Complete Within"
                                        name="completeWithin"
                                        labelAlign="left"
                                        colon={false}
                                        rules={[
                                            { required: true, message: 'Please enter number of days!' },
                                            { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only! Value from 1..180.' }
                                        ]}
                                        hasFeedback
                                        extra='Number of days user should complete this quest since accepted. Value from 1..180.'
                                    >
                                        <InputNumber placeholder='Days' min={1} max={180} />
                                    </Form.Item>
                                </>
                            }
                            <Form.Item
                                label="Welcome Quest Subject"
                                name="welcomeQuestMailSubject"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: false, message: 'Please enter subject.' }]}
                                hasFeedback
                                extra={'Welcome Quest email subject. If empty then default Welcome Quest subject will be used.'}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Welcome Quest Template ID"
                                name="welcomeQuestMailTemplateId"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: false, message: 'Please enter template id.' }]}
                                hasFeedback
                                extra={'Zoho ZepToMail Template ID. This needs to be customizable by project. We can custom by each template per Quest. If empty then default Welcome Quest email will be used.'}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Completed Quest Subject"
                                name="completedQuestMailSubject"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: false, message: 'Please enter subject.' }]}
                                hasFeedback
                                extra={'Completed Quest email subject. If empty then default Completed Quest subject will be used.'}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Completed Quest Template ID"
                                name="completedQuestMailTemplateId"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: false, message: 'Please enter template id.' }]}
                                hasFeedback
                                extra={'Zoho ZepToMail Template ID. This needs to be customizable by project. We can custom by each template per Quest. If empty then default Completed Quest email will be used.'}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Login Mode"
                                name="loginRequiredMode"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please select login mode!' }]}
                                hasFeedback
                                extra='Login mode when user doing this quest.'
                            >
                                <Select
                                    showSearch
                                    placeholder="Select mode"
                                    optionFilterProp="label"
                                    // onSelect={(e, s) => setRewardOption(s.rewardId)}
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    options={loginMode?.map((r, it) => ({ label: r?.label, value: r?.value }))}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                        </Panel>
                        <Panel header={<p className='font-bold text-lg py-0'>Activities</p>} key="2" className="bg-textbackgroundcolor">
                            {/* <Form.Item
                                label="Total number"
                                name="totalnumber"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Total number!' }]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item> */}
                            <Form.Item
                                label="Activities"
                                name="activityIds"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please select activities!' }]}
                                hasFeedback
                                extra={'Only activities has type as WEB available here'}
                            >
                                <Select
                                    mode='multiple'
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select activity"
                                    optionFilterProp='label'
                                    options={activityData?.result?.map(i => ({ label: `${i.name} (${transformValidationMethod(i.validationMethod)}, ${i.points}pts) (${i.cities && i.cities.length > 0 ? i.cities?.map(k => k.name).join(", ") : '-'})`, value: i._id }))}
                                >
                                </Select>
                            </Form.Item>
                        </Panel>
                        <Panel header={<p className='font-bold text-lg py-0'>Reward</p>} key="3" className="bg-textbackgroundcolor">
                            <Form.Item
                                label="Target Points"
                                name="points"
                                labelAlign="left"
                                colon={false}
                                rules={[
                                    { required: true, message: 'Please enter maximum points!' },
                                    { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                ]}
                                hasFeedback
                                extra='Target points to complete this quest. Value from 1..999999'
                            >
                                <InputNumber placeholder='Points' min={1} max={999999} />
                            </Form.Item>
                            <Form.Item
                                label="Reward"
                                name="rewardId"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please select reward type!' }]}
                                hasFeedback
                                extra='Award item when user complete this quest.'
                            >
                                <Select
                                    showSearch
                                    placeholder="Select Reward"
                                    optionFilterProp="label"
                                    onSelect={(e, s) => setRewardOption(s.rewardId)}
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    options={rewards?.map((r, it) => ({ label: r?.name, value: r?._id, rewardId: r?.rewardId }))}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                            {/* <Form.Item
                                label="Claim Limit"
                                name="claimLimit"
                                labelAlign="left"
                                colon={false}
                                extra={`No Limit: same as current user can redo the quest how many times they want.
                                Once per User: user can only do the quest once. They cannot redo the quest after completed.`}
                                rules={[{ required: false, message: 'Please select frequency!' }]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    placeholder="Select claim limit"
                                    optionFilterProp="label"
                                    onSelect={setClaimLimitOption}
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    options={claimLimitOptions ? claimLimitOptions.map(i => ({ label: i.label, value: i.value })) : []}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item> */}
                            {((rewardOption || questsData?.rewardId?.rewardId) == 'tree') && (
                                <Form.Item
                                    label="Number of tree"
                                    name="plantTree"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter extra point!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Trees' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {(rewardOption || questsData?.rewardId?.rewardId) == 'meal' && (
                                <Form.Item
                                    label="Number of meal"
                                    name="mealForChildren"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter extra point!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Meal' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {(rewardOption || questsData?.rewardId?.rewardId) == 'trash_remove' && (
                                <Form.Item
                                    label="Number of grams of trash"
                                    name="removeTrash"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter grams of trash!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Trash' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {(rewardOption || questsData?.rewardId?.rewardId) == 'xpoints' && (
                                <Form.Item
                                    label="Number of Points"
                                    name="extraPoint"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter extra point!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Points' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {(rewardOption || questsData?.rewardId?.rewardId) == 'voucher' && (
                                <>
                                    <Form.Item
                                        name='original_voucher_codes'
                                        label="Original Voucher Code"
                                        labelAlign="left"
                                        colon={false}
                                        extra={
                                            <>
                                                <p className='text-sm text-blue-500 pt-2 font-bold'>Here is original codes {numberVoucherOriginalEntered}. We are using this to calculate the logic when only 25% of vouchers are remaining.</p>
                                                <p className='text-sm py-2'>Please enter all code can be used seperated by comma.</p>
                                            </>
                                        }
                                    >
                                        <Input.TextArea rows={4} onChange={(e) => handleCountVoucherOriginal(e)} />
                                    </Form.Item>
                                    <Form.Item
                                        name='voucher_codes'
                                        label="Remaining Voucher Code"
                                        labelAlign="left"
                                        colon={false}
                                        extra={
                                            <>
                                                <p className='text-sm text-blue-500 pt-2 font-bold'>Total remaining codes available {numberVoucherEntered}. This is real-time sync whenever users earned the reward upon Quest completed.</p>
                                                <p className='text-sm py-2'>Please enter all code can be used seperated by comma.</p>
                                            </>
                                        }
                                    >
                                        <Input.TextArea rows={4} onChange={(e) => handleCountVoucher(e)} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Template ID"
                                        name="zepto_mail_template_id"
                                        labelAlign="left"
                                        colon={false}
                                        rules={[{ required: true, message: 'Please enter template id.' }]}
                                        hasFeedback
                                        extra={'Zoho ZepToMail Template ID. This needs to be customizable by project. We can custom by each template per Quest'}
                                    >
                                        <Input />
                                    </Form.Item>
                                </>
                            )}
                            {/* {(rewardOption || questsData?.rewardId?.rewardId) == 'voucher' && (
                                <Form.Item
                                    label="Email Content"
                                    name="email_content"
                                    labelAlign="left"
                                    colon={false}
                                    hasFeedback
                                    extra={
                                        <>
                                            <p className='text-sm py-2'>Template variable available: {`{{voucher_code}}, {{quest_name}}, {{email_logo}}`}.</p>
                                        </>
                                    }
                                >
                                    <Editor
                                        toolbar={{
                                            fontFamily: {
                                                options: ["Poppins"]
                                            }
                                        }}
                                        editorState={emailContentEditorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorEmailStateChange}
                                        onChange={() => {
                                            const tempData = (emailContentEditorState && draftToHtml(convertToRaw(emailContentEditorState.getCurrentContent())))
                                            setEmailContent(tempData)
                                        }

                                        }
                                    />
                                </Form.Item>
                            )}
                            {(rewardOption || questsData?.rewardId?.rewardId) == 'voucher' && (
                                <Form.Item
                                    labelAlign="left"
                                    colon={false}
                                    label="Email Logo"
                                    extra={
                                        <>
                                            <p className='py-2'>{SUPPORTED_FILE_FORMAT_EXTRA.QUEST_THUMB}</p>
                                            <p>This logo if uploaded will be used for the template variable {`{{email_logo}}`} when the system sending email to user</p>
                                        </>
                                    }
                                >
                                    <div className='flex justify-between'>
                                        <div className='self-center'>
                                            <p className='flex items-center'>Currently : <PreviewImage src={questsData?.rewardDetails?.voucher?.email_logo} width={120} height={69} /></p>
                                        </div>
                                        <Upload className='flex space-x-4' {...emailLogoProps} fileList={emailLogo}>
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </div>
                                </Form.Item>
                            )} */}
                        </Panel>
                        <Panel header={<p className='font-bold text-lg py-0'>Details</p>} key="4" className="bg-textbackgroundcolor" style={{ borderBottom: "none" }}>
                            <Form.Item
                                label="Description"
                                name="description"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter description!' }]}
                                hasFeedback
                            >
                                {/* <Input.TextArea showCount rows={3} /> */}
                                <Editor
                                    toolbar={{
                                        fontFamily: {
                                            options: ["Poppins"]
                                        }
                                    }}
                                    editorState={editorDescriptioDetailState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorDescriptionDetailStateChange}
                                    // plugins={plugins}
                                    onChange={() => {
                                        const tempData = (editorDescriptioDetailState && draftToHtml(convertToRaw(editorDescriptioDetailState.getCurrentContent())))
                                        setDescriptionDetail(tempData)
                                    }

                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Instruction"
                                name="instructions"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Instruction!' }]}
                                hasFeedback
                            >
                                <Input.TextArea showCount rows={3} />
                            </Form.Item>
                            <Form.Item
                                label="SDGs"
                                name="sdgs"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please select SDGs!' }]}
                                hasFeedback
                            >
                                <Select
                                    mode='multiple'
                                    showSearch
                                    placeholder="Multiple Selection"
                                    optionFilterProp="children"
                                    // onChange={onChange}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                // options={sdgs?.map(i => ({ label: i.name, value: i._id }))}
                                >
                                    {sdgs.map(i => (
                                        <Select.Option value={i._id}>{i.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Publish on listing"
                                name="publish"
                                colon={false}
                                labelAlign="left"
                                rules={[{ required: true, message: 'Publish is not selected!' }]}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <div className="text-center pt-2 pb-4">
                        <Button className="bg-[#207868]" size="large" type="primary" htmlType="submit">
                            Save Changes
                        </Button>
                    </div>
                </Form>
            </div >

        </>
    )
}